$.fn.isOnScreen = function(){

  var win = $(window);

  var viewport = {
    top : win.scrollTop(),
    left : win.scrollLeft()
  };

  viewport.right = viewport.left + win.width();
  viewport.bottom = viewport.top + win.height();

  var bounds = this.offset();
      bounds.right = bounds.left + this.outerWidth();
      bounds.bottom = bounds.top + this.outerHeight();

  return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));

};

var checkDivs = function(){
  $('.anim.start').each(function(){
    if($(this).isOnScreen() === true) {
      var anim = $(this).attr('data-anim');
      var speed = $(this).attr('data-speed');
      var delay = $(this).attr('data-delay');

      if(speed === null){
        speed = 500;
      }

      if(anim === 'fadeUp'){
        $(this).velocity({translateZ: 0, translateY: '-30px', opacity: 0}, {duration: 0, delay: 0});
        $(this).removeClass('start').addClass('finish');
        $(this).velocity({translateZ: 0, translateY: '0px', opacity: 1}, {duration: speed, delay: delay});
      }

      if(anim === 'fadeDown'){
        $(this).velocity({translateZ: 0, translateY: '30px', opacity: 0}, {duration: 0, delay: 0});
        $(this).removeClass('start').addClass('finish');
        $(this).velocity({translateZ: 0, translateY: '0px', opacity: 1}, {duration: speed, delay: delay});
      }

      if(anim === 'fadeLeft'){
        $(this).velocity({translateZ: 0, translateX: '-30px', opacity: 0}, {duration: 0, delay: 0});
        $(this).removeClass('start').addClass('finish');
        $(this).velocity({translateZ: 0, translateX: '0px', opacity: 1}, {duration: speed, delay: delay});
      }

      if(anim === 'fadeRight'){
        $(this).velocity({translateZ: 0, translateX: '30px', opacity: 0}, {duration: 0, delay: 0});
        $(this).removeClass('start').addClass('finish');
        $(this).velocity({translateZ: 0, translateX: '0px', opacity: 1}, {duration: speed, delay: delay});
      }
    }
  });
};

$(window).scroll(function(){
  checkDivs();
});

$(document).ready(function(){
  $('.anim').each(function(){
    $(this).addClass('start').css('opacity', 0);
  });
  checkDivs();
});
